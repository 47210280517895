import { Meta, Owner, Pet } from "@/shared/types/global-store"
import { PricingResponse } from "@/shared/types/lead-api-types"
import { generateUserId } from "@/shared/utils/helpers"
import toast from "react-hot-toast"
import { createMachine } from "xstate"

import type * as T from "./helpers"
import {
  addAttributionQuestion,
  addNewPet,
  addOwnerAddress,
  addOwnerBirthdayAndBirthCity,
  addOwnerMailAndPhone,
  addOwnerMailAndPhoneVariantB,
  addOwnerName,
  addPetBirthDate,
  addPetBreed,
  addPetDiseases,
  addPetHealth,
  addPetName,
  addPetSex,
  addPetType,
  addPricingPlan,
  addPromoCode,
  addQuoteId,
  addStartDate,
  choosePrepackage,
  clearDraftPets,
  customizeCoverage,
  deletePet,
  matchHasMultiplePets,
  matchHasValidPet,
  resetMachine,
  setFirstPet,
  setLastPet,
  setLoadingPrepackage,
  setNextPet,
  setPreviousPet,
  setStep,
  stopLoadingPrepackage,
  syncBackHelper as syncBack,
  updatePet,
  updatePrepackageB,
  updatePrepackagePrice,
  updatePricingPlan,
  updatePricingPlanSimple,
} from "./helpers"

export type Marketing = {
  referrer_details?: string
  referrer_type?: string
}

export type SubscriptionFlowContext = {
  pets: Pet[]
  currentPetId?: string
  owner: Owner
  meta: Meta
  pricing?: PricingResponse
  marketing: Marketing
  step: number
  setLoadingPrepackage: boolean
}

export type SubscriptionFlowEvents =
  | T.AddPetNameEvent
  | T.AddPetSexEvent
  | T.AddPetTypeEvent
  | T.AddPetBreedEvent
  | T.AddPetBirthDateEvent
  | T.AddNewPetEvent
  | T.UpdatePetEvent
  | T.DeletePetEvent
  | T.ValidateTribeEvent
  | T.AddOwnerNameEvent
  | T.AddOwnerMailAndPhoneEvent
  | T.CustomizeCoverageEvent
  | T.ChoosePrepackageEvent
  | T.AgreeCoverageEvent
  | T.AddPricingPlanEvent
  | T.UpdatePricingPlanEvent
  | T.UpdatePricingPlanWithoutRedirectEvent
  | T.ValidatePricingPlanEvent
  | T.AddQuoteIdEvent
  | T.ValidatePetPlansEvent
  | T.AddOwnerAddressEvent
  | T.AddOwnerBirthDateAndBirthCityEvent
  | T.ResetMachineEvent
  | T.GoToPriviousStateEvent
  | T.AddAttributionQuestionEvent
  | T.AddPetHealthEvent
  | T.AddPetDiseasesEvent
  | T.ValidatePricingPlanParametersEvent
  | T.SetStepEvent
  | T.StopLoadingPrepackageEvent
  | T.AddOwnerMailAndPhoneVariantBEvent
  | T.AddStartDateEvent
  | T.UpdatePrepackagePriceEvent
  | T.UpdatePricingPlanPrepackageBEvent
  | {
      type:
        | "GO_TO_PET_NAME"
        | "GO_TO_PET_SEX"
        | "GO_TO_PET_BIRTHDATE"
        | "GO_TO_PET_ID"
        | "GO_TO_PET_TYPE"
        | "GO_TO_PET_BREED"
        | "GO_TO_TRIBE"
        | "GO_TO_OWNER_NAME"
        | "GO_TO_OWNER_MAIL_AND_PHONE"
        | "GO_TO_OWNER_ADDRESS"
        | "GO_TO_OWNER_BIRTHDATE"
        | "GO_TO_TRIBE_QUOTE"
        | "GO_TO_PREPACKAGES"
        | "GO_TO_COVERAGE"
        | "GO_TO_PARAMETERS"
        | "GO_TO_PRICING"
        | "GO_TO_RECAP"
        | "GO_TO_ATTRIBUTION_QUESTION"
        | "GO_TO_PET_HEALTH"
        | "GO_TO_PET_OLD_DISEASES"
        | "ADD_PET_NOT_HEALTHY"
        | "SET_STEP"
        | "ADD_START_DATE"
        | "UPDATE_PREPACKAGE_PRICE"
        | "UPDATE_PRICING_PLAN_VARIANT_B"
    }

const actions = {
  addNewPet,
  addOwnerMailAndPhone,
  addOwnerName,
  addPetBirthDate,
  addPetName,
  addPetSex,
  addPetType,
  addPetBreed,
  customizeCoverage,
  choosePrepackage,
  deletePet,
  updatePet,
  addPricingPlan,
  updatePricingPlan,
  updatePricingPlanSimple,
  addQuoteId,
  addOwnerAddress,
  addOwnerBirthdayAndBirthCity,
  clearDraftPets,
  addOwnerMailAndPhoneVariantB,
  resetMachine,
  syncBack,
  addAttributionQuestion,
  addPetHealth,
  addPetDiseases,
  setPreviousPet,
  setFirstPet,
  setNextPet,
  setLastPet,
  addPromoCode,
  setStep,
  setLoadingPrepackage,
  stopLoadingPrepackage,
  addStartDate,
  updatePrepackagePrice,
  updatePrepackageB,
}

export const subscriptionFlowMachine = createMachine(
  {
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as SubscriptionFlowContext,
      events: {} as SubscriptionFlowEvents,
    },
    id: "subscription-flow",
    context: {
      pets: [],
      owner: {} as Owner,
      meta: {
        user_id: generateUserId(),
      } as Meta,
      marketing: {},
      step: 1,
      setLoadingPrepackage: false,
    },
    initial: "landing",
    states: {
      landing: {
        initial: "quoteCreation",
        states: {
          quoteCreation: {
            invoke: {
              id: "create-quote",
              src: "createQuote",
              onDone: {
                actions: "addQuoteId",
                target: "handleUrlParams",
              },
              onError: {
                actions: (...rest) => {
                  // Usefull for debugging
                  console.log(rest)
                  toast.error("API Create Quote Error")
                },
                target: "handleUrlParams",
              },
            },
          },
          handleUrlParams: {
            invoke: {
              id: "handle-url-params",
              src: "urlParamsHandler",
              onDone: [
                {
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.petInformations.petBreed",
                  cond: "hasNoBreedFromLp",
                },
                {
                  target: "#subscription-flow.pricing.pricing",
                  cond: "isQuoteCustomized",
                },
                {
                  target: "#subscription-flow.pricing.prepackages",

                  cond: "hastValidPets",
                },
                {
                  target: "#subscription-flow.petInformations",
                },
              ],
            },
          },
        },
      },
      petInformations: {
        initial: "petName",
        states: {
          petName: {
            entry: ["goToPetName", "addPromoCode"],
            on: {
              ADD_PET_NAME: {
                actions: "addPetName",
                target: "petSex",
              },
              PREVIOUS: {
                target: "tribe",
                cond: "hastValidPets",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petSex: {
            entry: "goToPetSex",
            on: {
              ADD_PET_SEX: {
                actions: "addPetSex",
                target: "petType",
              },
              PREVIOUS: {
                target: "petName",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petType: {
            entry: "goToPetType",
            on: {
              ADD_PET_TYPE: {
                actions: "addPetType",
                target: "petBreed",
              },
              PREVIOUS: {
                target: "petSex",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petBreed: {
            entry: "goToPetBreed",
            on: {
              ADD_PET_BREED: {
                actions: "addPetBreed",
                target: "petBirthDate",
              },
              PREVIOUS: {
                target: "petType",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petBirthDate: {
            entry: "goToPetBirthDate",
            on: {
              ADD_PET_BIRTH_DATE: {
                actions: "addPetBirthDate",
              },
              GO_TO_TRIBE: {
                target: "tribe",
              },
              PREVIOUS: {
                target: "petBreed",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          tribe: {
            entry: ["goToTribe", "clearDraftPets"],
            on: {
              ADD_NEW_PET: {
                actions: "addNewPet",
                target: "petName",
              },
              UPDATE_PET: {
                actions: "updatePet",
                target: "petName",
              },
              DELETE_PET: {
                actions: "deletePet",
                cond: "hasMultiplePets",
              },
              VALIDATE_TRIBE: {
                target: "#subscription-flow.ownerInformations.attribitionQuestion",
              },
              PREVIOUS: {
                target: "petBirthDate",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      ownerInformations: {
        states: {
          attribitionQuestion: {
            entry: "goToAttribitionQuestion",
            on: {
              ADD_ATTRIBUTION_QUESTION: {
                actions: ["addAttributionQuestion", "syncBack"],
                target: "ownerMailAndPhone",
              },
              PREVIOUS: {
                target: "#subscription-flow.petInformations.tribe",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerMailAndPhone: {
            entry: "goToOwnerMailAndPhone",
            on: {
              ADD_OWNER_MAIL_AND_PHONE: [
                {
                  actions: ["addOwnerMailAndPhone"],
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  actions: ["addOwnerMailAndPhone", "setFirstPet", "setLoadingPrepackage"],
                  target: "#subscription-flow.pricing.prepackages",
                },
              ],
              ADD_OWNER_MAIL_AND_PHONE_VARIANT_B: [
                {
                  actions: ["addOwnerMailAndPhoneVariantB"],
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  actions: ["addOwnerMailAndPhoneVariantB", "setFirstPet", "setLoadingPrepackage"],
                  target: "#subscription-flow.pricing.prepackages",
                },
              ],
              PREVIOUS: {
                target: "attribitionQuestion",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      pricing: {
        states: {
          tribeQuote: {
            entry: "goToTribeQuote",
            on: {
              ADD_NEW_PET: {
                actions: "addNewPet",
                target: "#subscription-flow.petInformations.petName",
              },
              UPDATE_PET: {
                actions: "updatePet",
                target: "#subscription-flow.petInformations.petName",
              },
              DELETE_PET: {
                actions: "deletePet",
                cond: "hasMultiplePets",
              },
              CUSTOMIZE_COVERAGE: {
                actions: "customizeCoverage",
                target: "#subscription-flow.pricing.prepackages",
              },
              VALIDATE_PET_PLANS: {
                target: "#subscription-flow.petInformationsPart2.petHealth",
                actions: "setFirstPet",
                cond: "isAllPetsPriced",
              },
              PREVIOUS: {
                target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          coverage: {
            entry: "goToCoverage",
            on: {
              AGREE_COVERAGE: {
                target: "prepackages",
              },
              PREVIOUS: [
                {
                  target: "tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
                },
              ],
            },
          },
          prepackages: {
            entry: "goToPrepackages",
            on: {
              ADD_START_DATE: {
                actions: "addStartDate",
              },
              UPDATE_PRICING_PLAN_WITHOUT_REDIRECT: {
                actions: "updatePricingPlanSimple",
              },

              UPDATE_PRICING_PLAN_VARIANT_B: [
                {
                  actions: "updatePrepackageB",
                  target: "parameters",
                  cond: "isPrepackagesVariantB",
                },
              ],

              UPDATE_PRICING_PLAN: [
                {
                  actions: "updatePricingPlan",
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  actions: ["updatePricingPlan", "setFirstPet"],
                  target: "#subscription-flow.petInformationsPart2.petHealth",
                },
              ],
              UPDATE_PREPACKAGE_PRICE: {
                actions: "updatePrepackagePrice",
              },
              CHOOSE_PREPACKAGE: {
                actions: "choosePrepackage",
              },
              STOP_LOADING_PREPACKAGE: {
                actions: "stopLoadingPrepackage",
              },
              CUSTOMIZE_COVERAGE: {
                actions: "customizeCoverage",
                target: "parameters",
              },
              PREVIOUS: [
                {
                  target: "#subscription-flow.pricing.tribeQuote",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          parameters: {
            entry: "goToParameters",
            on: {
              ADD_START_DATE: {
                actions: "addStartDate",
              },
              PREVIOUS: {
                target: "prepackages",
              },
              ADD_PRICING_PLAN: {
                actions: "addPricingPlan",
              },
              VALIDATE_PRICING_PLAN_PARAMETERS: {
                target: "pricing",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          pricing: {
            entry: ["goToPricing", "addPromoCode"],
            on: {
              UPDATE_PRICING_PLAN_WITHOUT_REDIRECT: {
                actions: "updatePricingPlanSimple",
              },
              UPDATE_PRICING_PLAN: {
                actions: "updatePricingPlan",
              },
              VALIDATE_PRICING_PLAN: [
                {
                  target: "tribeQuote",
                  cond: "isFromRedirectEmailOrPartner",
                },
                {
                  target: "tribeQuote",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.petInformationsPart2.petHealth",
                  actions: "setFirstPet",
                },
              ],
              PREVIOUS: {
                target: "parameters",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      petInformationsPart2: {
        initial: "petHealth",
        states: {
          petHealth: {
            entry: ["goToPetHealth"],
            on: {
              ADD_PET_HEALTH: [
                {
                  actions: ["addPetHealth", "setNextPet", "syncBack"],
                  target: "petHealth",
                  cond: "isNotLastPet",
                },
                {
                  actions: ["addPetHealth", "syncBack"],
                  target: "#subscription-flow.ownerInformationsPart2.ownerName",
                },
              ],
              ADD_PET_NOT_HEALTHY: {
                target: "petOldDiseases",
              },
              PREVIOUS: [
                {
                  target: "#subscription-flow.pricing.tribeQuote",
                  cond: "isFirstPet",
                },
                {
                  target: "petHealth",
                  actions: "setPreviousPet",
                  cond: "hasMultiplePets",
                },
                {
                  target: "#subscription-flow.pricing.prepackages",
                  cond: "choosePrepackage",
                },
                {
                  target: "#subscription-flow.pricing.pricing",
                },
              ],
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          petOldDiseases: {
            entry: ["goToPetOldDiseases"],
            on: {
              ADD_PET_DISEASES: [
                {
                  cond: "isLastPet",
                  actions: ["addPetDiseases", "syncBack"],
                  target: "#subscription-flow.ownerInformationsPart2.ownerName",
                },
                {
                  actions: ["addPetDiseases", "setNextPet", "syncBack"],
                  target: "petHealth",
                },
              ],
              PREVIOUS: {
                target: "petHealth",
              },
            },
          },
        },
      },
      ownerInformationsPart2: {
        states: {
          ownerName: {
            entry: "goToOwnerInformations",
            on: {
              ADD_OWNER_NAME: {
                actions: ["addOwnerName", "syncBack"],
                target: "ownerAddress",
              },
              PREVIOUS: {
                target: "#subscription-flow.petInformationsPart2.petHealth",
                actions: "setLastPet",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerAddress: {
            entry: "goToOwnerAddress",
            on: {
              ADD_OWNER_ADDRESS: {
                actions: ["addOwnerAddress", "syncBack"],
                target: "ownerBirthDate",
              },
              PREVIOUS: {
                target: "ownerName",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
          ownerBirthDate: {
            entry: "goToOwnerBirthDate",
            on: {
              ADD_OWNER_BIRTHDATE_AND_BIRTHCITY: {
                actions: ["addOwnerBirthdayAndBirthCity", "syncBack"],
                target: "#subscription-flow.recap",
              },
              PREVIOUS: {
                target: "ownerAddress",
              },
              SET_STEP: {
                actions: "setStep",
              },
            },
          },
        },
      },
      recap: {
        entry: "goToRecap",
        on: {
          UPDATE_PET: {
            actions: "updatePet",
            target: "petInformations.petName",
          },
          DELETE_PET: {
            actions: "deletePet",
            cond: "hasMultiplePets",
          },
          CUSTOMIZE_COVERAGE: [
            {
              actions: "customizeCoverage",
              target: "#subscription-flow.pricing.prepackages",
              cond: "choosePrepackage",
            },
            {
              actions: "customizeCoverage",
              target: "#subscription-flow.pricing.pricing",
            },
          ],
          PREVIOUS: {
            target: "#subscription-flow.ownerInformationsPart2.ownerBirthDate",
          },
          SET_STEP: {
            actions: "setStep",
          },
        },
      },
    },
    on: {
      // Event to trigger a redirect
      GO_TO_PET_NAME: {
        target: "#subscription-flow.petInformations.petName",
        cond: "hasQuoteId",
      },
      GO_TO_PET_SEX: {
        target: "#subscription-flow.petInformations.petSex",
      },
      GO_TO_PET_BIRTHDATE: {
        target: "#subscription-flow.petInformations.petBirthDate",
      },
      GO_TO_PET_TYPE: {
        target: "#subscription-flow.petInformations.petType",
      },
      GO_TO_PET_BREED: {
        target: "#subscription-flow.petInformations.petBreed",
      },
      GO_TO_TRIBE: {
        target: "#subscription-flow.petInformations.tribe",
      },
      GO_TO_OWNER_NAME: {
        target: "#subscription-flow.ownerInformationsPart2.ownerName",
      },
      GO_TO_OWNER_MAIL_AND_PHONE: {
        target: "#subscription-flow.ownerInformations.ownerMailAndPhone",
      },
      GO_TO_OWNER_ADDRESS: {
        target: "#subscription-flow.ownerInformationsPart2.ownerAddress",
      },
      GO_TO_OWNER_BIRTHDATE: {
        target: "#subscription-flow.ownerInformationsPart2.ownerBirthDate",
      },
      GO_TO_TRIBE_QUOTE: {
        target: "#subscription-flow.pricing.tribeQuote",
      },
      GO_TO_PREPACKAGES: {
        target: "#subscription-flow.pricing.prepackages",
      },
      GO_TO_COVERAGE: {
        target: "#subscription-flow.pricing.coverage",
      },
      GO_TO_PARAMETERS: {
        target: "#subscription-flow.pricing.parameters",
      },
      GO_TO_PRICING: {
        target: "#subscription-flow.pricing.pricing",
      },
      GO_TO_RECAP: {
        target: "#subscription-flow.recap",
      },
      GO_TO_ATTRIBUTION_QUESTION: {
        target: "#subscription-flow.ownerInformations.attribitionQuestion",
      },
      GO_TO_PET_HEALTH: {
        target: "#subscription-flow.petInformationsPart2.petHealth",
      },
      GO_TO_PET_OLD_DISEASES: {
        target: "#subscription-flow.petInformationsPart2.petOldDiseases",
      },
      // Global events
      RESET_MACHINE: {
        actions: "resetMachine",
        target: "#subscription-flow.petInformations.petName",
      },
    },
  },
  {
    guards: {
      hasQuoteId: (ctx) => Boolean(ctx.meta.quote_id),
      hasMultiplePets: (ctx) => matchHasMultiplePets(ctx.pets),
      isAllPetsPriced: (ctx) => ctx.pets.every((pet) => pet.selectedFormula),
      hastValidPets: (ctx) => matchHasValidPet(ctx.pets),
      isFirstPet: (ctx) =>
        ctx.currentPetId === ctx.pets.at(0)?.id && matchHasMultiplePets(ctx.pets),
      isLastPet: (ctx) => ctx.currentPetId === ctx.pets.at(-1)?.id,
      isNotLastPet: (ctx) => ctx.currentPetId !== ctx.pets.at(-1)?.id,
      choosePrepackage: (ctx) =>
        Boolean(ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.choosePrepackage),
      isQuoteCustomized: (ctx) =>
        Boolean(ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.isQuoteCustomized),
      hasNoBreedFromLp: (ctx) =>
        Boolean(
          !ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.breed?.race && ctx.meta.from_lp
        ),
      isFromRedirectEmailOrPartner: (ctx) => ctx.meta.from_lp && !ctx.meta.raf_code,
      isPrepackagesVariantB: (ctx) => ctx.meta.ab_tests?.prepackages.variant === "B",
    },
    // @ts-expect-error this is temporary until we found what's wrong with this types
    actions,
  }
)
