import { isPetTooYoung } from "@/modules/Pet/helpers/helpers"
import { buildSyncBackBody } from "@/modules/Quote/hooks/use-sync-back"
import { syncBack } from "@/shared/http"
import type { Breed, Meta, Owner, OwnerAddress, Pet, PetHealth } from "@/shared/types/global-store"
import {
  CreateQuoteResponse,
  NewQuoteResponse,
  PetOldDiseases,
} from "@/shared/types/lead-api-types"
import { generatePetId } from "@/shared/utils/helpers"
import { assign } from "@xstate/immer"
import { addMonths } from "date-fns"
import { TrackJS } from "trackjs"

import { clearState } from "./global-store"
import { SubscriptionFlowContext } from "./machine"

export type AddPetNameEvent = {
  type: "ADD_PET_NAME"
  input: {
    petName: string
  }
}

export type AddPetSexEvent = {
  type: "ADD_PET_SEX"
  input: {
    petSex: Pet["sex"]
  }
}

export type AddPetTypeEvent = {
  type: "ADD_PET_TYPE"
  input: {
    petType: Pet["type"]
  }
}

export type AddPetBreedEvent = {
  type: "ADD_PET_BREED"
  input: {
    petBreed: Breed
  }
}

export type AddPetBirthDateEvent = {
  type: "ADD_PET_BIRTH_DATE"
  input: {
    petBirthDate: string
  }
}

export type AddNewPetEvent = {
  type: "ADD_NEW_PET"
  input: undefined
}

export type UpdatePetEvent = {
  type: "UPDATE_PET"
  input: {
    petId: string
  }
}

export type DeletePetEvent = {
  type: "DELETE_PET"
  input: {
    petId: string
  }
}

export type ValidateTribeEvent = {
  type: "VALIDATE_TRIBE"
  input: undefined
}

export type AddOwnerNameEvent = {
  type: "ADD_OWNER_NAME"
  input: {
    firstname: string
    lastname: string
  }
}

export type AddOwnerMailAndPhoneEvent = {
  type: "ADD_OWNER_MAIL_AND_PHONE"
  input: {
    email: string
    phone: string
    firstname: string
    lastname: string
    prepackagesVariant: "A" | "B" | "default"
  }
}

export type AddOwnerMailAndPhoneVariantBEvent = {
  type: "ADD_OWNER_MAIL_AND_PHONE_VARIANT_B"
  input: {
    email: string
    phone: string
    firstname: string
    lastname: string
    prepackagesVariant: "A" | "B" | "default"
  }
}

export type AddStartDateEvent = {
  type: "ADD_START_DATE"
  input: {
    startDate: string | Date
  }
}

export type AgreeCoverageEvent = {
  type: "AGREE_COVERAGE"
}

export type CustomizeCoverageEvent = {
  type: "CUSTOMIZE_COVERAGE"
  input: {
    petId: string
  }
}

export type ChoosePrepackageEvent = {
  type: "CHOOSE_PREPACKAGE"
  input: {
    petId: string
    choosePrepackage: boolean
  }
}

export type UpdatePrepackagePriceEvent = {
  type: "UPDATE_PREPACKAGE_PRICE"
  pets: {
    petId: string
    price: number
    prevention: boolean
    prevention_limit: number
    prevention_price: number
  }
}

export type AddPricingPlanEvent = {
  type: "ADD_PRICING_PLAN"
  input: {
    rate: string
    health_limit: string
    price: number
    prevention: boolean
    prevention_limit: number
    prevention_price: number
    start_date?: Date | string
    subscription_mode?: "ASAP" | "DEFERRED"
  }
}
export type ValidatePricingPlanParametersEvent = {
  type: "VALIDATE_PRICING_PLAN_PARAMETERS"
  input: {
    rate: string
    health_limit: string
    price: number
    prevention: boolean
    prevention_limit: number
    prevention_price: number
    start_date?: Date | string
  }
}

export type UpdatePricingPlanEvent = {
  type: "UPDATE_PRICING_PLAN"
  input: {
    rate: string
    health_limit: string
    price: number
    prevention: boolean
    prevention_limit: number
    prevention_price: number
    start_date?: Date | string
    subscription_mode?: "ASAP" | "DEFERRED"
  }
}

export type UpdatePricingPlanWithoutRedirectEvent = {
  type: "UPDATE_PRICING_PLAN_WITHOUT_REDIRECT"
  input: {
    start_date?: Date | string
    subscription_mode?: "ASAP" | "DEFERRED"
  }
}

export type UpdatePricingPlanPrepackageBEvent = {
  type: "UPDATE_PRICING_PLAN_VARIANT_B"
  input: {
    rate: string
    health_limit: string
    price: number
    prevention: boolean
    prevention_limit: number
    prevention_price: number
    start_date?: Date | string
    subscription_mode?: "ASAP" | "DEFERRED"
  }
}

export type ValidatePricingPlanEvent = {
  type: "VALIDATE_PRICING_PLAN"
  input: undefined
}

export type AddQuoteIdEvent = {
  type: "ADD_QUOTE_ID"
  data: CreateQuoteResponse | NewQuoteResponse
}

export type ValidatePetPlansEvent = {
  type: "VALIDATE_PET_PLANS"
  input: undefined
}

export type AddOwnerAddressEvent = {
  type: "ADD_OWNER_ADDRESS"
  input: OwnerAddress
}

export type AddOwnerBirthDateAndBirthCityEvent = {
  type: "ADD_OWNER_BIRTHDATE_AND_BIRTHCITY"
  input: {
    birthday: string
    birthcity: string
  }
}

export type GoToPriviousStateEvent = {
  type: "PREVIOUS"
  input: undefined
}

export type ResetMachineEvent = {
  type: "RESET_MACHINE"
  input: undefined
}

export type AddAttributionQuestionEvent = {
  type: "ADD_ATTRIBUTION_QUESTION"
  input: {
    referrer_details: string
    referrer_type: string
  }
}

export type AddPetHealthEvent = {
  type: "ADD_PET_HEALTH"
  input: PetHealth
}

export type AddPetDiseasesEvent = {
  type: "ADD_PET_DISEASES"
  input: {
    diseases: PetOldDiseases[]
    other_diseases?: string
  }
}

export type ContinueWithoutWellnessEvent = {
  type: "CONTINUE_WITHOUT_WELLNESS"
  input: number
}

export type SetStepEvent = {
  type: "SET_STEP"
  input: number
}

export type StopLoadingPrepackageEvent = {
  type: "STOP_LOADING_PREPACKAGE"
}

export const addPetName = assign<SubscriptionFlowContext, AddPetNameEvent>((ctx, { input }) => {
  // If we have a petId, we are editing an existing pet
  if (ctx.currentPetId) {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    if (!pet) {
      // TODO: handle error. This should never happen
      return
    }

    pet.name = input.petName

    return
  }

  // If we don't have a petId, we are adding a new pet
  const newPetId = generatePetId()

  ctx.pets.push({
    name: input.petName,
    id: newPetId,
    idx: 0,
    isPetDraft: true,
    isQuoteCustomized: false,
    uuid: "11223344",
    uuid_type: "chip",
  } as Pet)

  ctx.currentPetId = newPetId
})

export const addPetSex = assign<SubscriptionFlowContext, AddPetSexEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.sex = input.petSex
})

export const addPetType = assign<SubscriptionFlowContext, AddPetTypeEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.type = input.petType
})

export const addPetBreed = assign<SubscriptionFlowContext, AddPetBreedEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.breed = input.petBreed
})

function getMinFirstDate(birthday: Date) {
  const eligibleDate = addMonths(birthday, 2)
  return eligibleDate
}

export const addPetBirthDate = assign<SubscriptionFlowContext, AddPetBirthDateEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    const formattedBirthDate = new Date(input.petBirthDate)

    if (isPetTooYoung(formattedBirthDate, new Date())) {
      ctx.meta.start_date = getMinFirstDate(formattedBirthDate)
      ctx.meta.subscription_mode = "DEFERRED"
    }

    pet.birthday = input.petBirthDate
    pet.isPetDraft = false
  }
)

export const addNewPet = assign<SubscriptionFlowContext, AddNewPetEvent>((ctx) => {
  // If we don't have a petId, we are adding a new pet
  const newPetId = generatePetId()

  const highestIdx = Math.max(...ctx.pets.map((pet) => pet.idx))

  ctx.pets.push({
    id: newPetId,
    idx: highestIdx + 1,
    isPetDraft: true,
    isQuoteCustomized: false,
    uuid: "11223344",
    uuid_type: "chip",
  } as Pet)

  ctx.currentPetId = newPetId
})

export const updatePet = assign<SubscriptionFlowContext, UpdatePetEvent>((ctx, { input }) => {
  ctx.currentPetId = input.petId
})

export const deletePet = assign<SubscriptionFlowContext, DeletePetEvent>((ctx, { input }) => {
  ctx.pets = ctx.pets.filter((pet) => pet.id !== input.petId)
  ctx.currentPetId = ctx.pets[0]?.id
})

export const addOwnerName = assign<SubscriptionFlowContext, AddOwnerNameEvent>((ctx, { input }) => {
  ctx.owner.firstname = input.firstname
  ctx.owner.lastname = input.lastname
})

export const addOwnerMailAndPhone = assign<SubscriptionFlowContext, AddOwnerMailAndPhoneEvent>(
  (ctx, { input }) => {
    ctx.owner.email = input.email
    ctx.owner.phone = input.phone
    ctx.owner.firstname = input.firstname
    ctx.owner.lastname = input.lastname
    ctx.meta.ab_tests = { prepackages: { variant: input.prepackagesVariant } }
  }
)

export const addOwnerMailAndPhoneVariantB = assign<
  SubscriptionFlowContext,
  AddOwnerMailAndPhoneVariantBEvent
>((ctx, { input }) => {
  ctx.owner.email = input.email
  ctx.owner.phone = input.phone
  ctx.owner.firstname = input.firstname
  ctx.owner.lastname = input.lastname
  ctx.meta.ab_tests = { prepackages: { variant: input.prepackagesVariant } }
})

export const customizeCoverage = assign<SubscriptionFlowContext, CustomizeCoverageEvent>(
  (ctx, { input }) => {
    ctx.currentPetId = input.petId
  }
)

export const choosePrepackage = assign<SubscriptionFlowContext, ChoosePrepackageEvent>(
  (ctx, { input }) => {
    ctx.currentPetId = input.petId
    const pet = ctx.pets.find((pet) => pet.id === input.petId)
    if (!pet) {
      return
    }
    pet.choosePrepackage = input.choosePrepackage
  }
)

export const addStartDate = assign<SubscriptionFlowContext, AddStartDateEvent>((ctx, { input }) => {
  ctx.meta.start_date = input.startDate
})

export const addPricingPlan = assign<SubscriptionFlowContext, AddPricingPlanEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)
    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }
    pet.selectedFormula = {
      ...input,
    }
    pet.isQuoteCustomized = true
    if (input.start_date) {
      ctx.meta.start_date = input.start_date
    }
    if (input.subscription_mode) {
      ctx.meta.subscription_mode = input.subscription_mode
    }
  }
)

export const updatePrepackagePrice = assign<SubscriptionFlowContext, UpdatePrepackagePriceEvent>(
  (ctx, { pets }) => {
    const currentPet = ctx.pets.find((pet) => pet.id === pets.petId)
    if (!currentPet) {
      return
    }
    if (currentPet.selectedFormula) {
      currentPet.selectedFormula.price = pets.price
      currentPet.selectedFormula.prevention_limit = pets.prevention_limit
      currentPet.selectedFormula.prevention_price = pets.prevention_price
      currentPet.selectedFormula.prevention = pets.prevention
    }
  }
)

export const updatePrepackageB = assign<SubscriptionFlowContext, UpdatePricingPlanPrepackageBEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.selectedFormula = {
      ...pet.selectedFormula,
      ...input,
    }
    pet.isQuoteCustomized = true
    if (input.start_date) {
      ctx.meta.start_date = input.start_date
    }
    if (input.subscription_mode) {
      ctx.meta.subscription_mode = input.subscription_mode
    }
  }
)

export const updatePricingPlanSimple = assign<
  SubscriptionFlowContext,
  UpdatePricingPlanWithoutRedirectEvent
>((ctx, { input }) => {
  if (input.start_date) {
    ctx.meta.start_date = input.start_date
  }
  if (input.subscription_mode) {
    ctx.meta.subscription_mode = input.subscription_mode
  }
})

export const updatePricingPlan = assign<SubscriptionFlowContext, UpdatePricingPlanEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.selectedFormula = {
      ...pet.selectedFormula,
      ...input,
    }
    pet.isQuoteCustomized = true
    if (input.start_date) {
      ctx.meta.start_date = input.start_date
    }
    if (input.subscription_mode) {
      ctx.meta.subscription_mode = input.subscription_mode
    }
  }
)

export const addQuoteId = assign<SubscriptionFlowContext, AddQuoteIdEvent>((ctx, event) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const quoteId = event.data?.data?.quote_id || event.data?.[0]?.quote_id || ""
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  TrackJS.addMetadata("quote_id", quoteId)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  ctx.meta.quote_id = quoteId

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (quoteId === localStorage.getItem("quoteId")) {
    localStorage.removeItem("quoteId")
  }
})

export const addOwnerAddress = assign<SubscriptionFlowContext, AddOwnerAddressEvent>(
  (ctx, { input }) => {
    ctx.owner.address = input
  }
)

export const addOwnerBirthdayAndBirthCity = assign<
  SubscriptionFlowContext,
  AddOwnerBirthDateAndBirthCityEvent
>((ctx, { input }) => {
  ctx.owner.birthday = input.birthday
  ctx.owner.birthcity = input.birthcity
})

export const clearDraftPets = assign<SubscriptionFlowContext>((ctx) => {
  ctx.pets = ctx.pets.filter((pet) => !pet.isPetDraft)
})

export const resetMachine = assign<SubscriptionFlowContext>((ctx) => {
  clearState()

  ctx.pets = []
  ctx.owner = {} as Owner
  ctx.meta = {} as Meta
})

export const setLoadingPrepackage = assign<SubscriptionFlowContext>((ctx) => {
  return (ctx.setLoadingPrepackage = true)
})

export const stopLoadingPrepackage = assign<SubscriptionFlowContext>((ctx) => {
  return (ctx.setLoadingPrepackage = false)
})

export function matchHasValidPet(pets: Pet[]) {
  return pets.some((pet) => !pet.isPetDraft)
}

export function matchHasMultiplePets(pets: Pet[]) {
  return pets.filter((pet) => !pet.isPetDraft).length > 1
}

export function syncBackHelper(ctx: SubscriptionFlowContext) {
  return syncBack(buildSyncBackBody(ctx))
}

export const addAttributionQuestion = assign<SubscriptionFlowContext, AddAttributionQuestionEvent>(
  (ctx, { input }) => {
    ctx.marketing.referrer_details = input.referrer_details
    ctx.marketing.referrer_type = input.referrer_type
  }
)

export const addPetHealth = assign<SubscriptionFlowContext, AddPetHealthEvent>((ctx, { input }) => {
  const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

  // TODO: handle error. This should never happen
  if (!pet) {
    return
  }

  pet.health = input
})

export const addPetDiseases = assign<SubscriptionFlowContext, AddPetDiseasesEvent>(
  (ctx, { input }) => {
    const pet = ctx.pets.find((pet) => pet.id === ctx.currentPetId)

    // TODO: handle error. This should never happen
    if (!pet) {
      return
    }

    pet.health = {
      status: "not_healthy",
      old_diseases: input.diseases,
      other_diseases: input.other_diseases,
    }
  }
)

export const setLastPet = assign<SubscriptionFlowContext>((ctx) => {
  ctx.currentPetId = ctx.pets?.at(-1)?.id
})

export const setFirstPet = assign<SubscriptionFlowContext>((ctx) => {
  ctx.currentPetId = ctx.pets.at(0)?.id
})

export const setPreviousPet = assign<SubscriptionFlowContext>((ctx) => {
  const currentPetIndex = ctx.pets.findIndex((pet) => pet.id === ctx.currentPetId)
  ctx.currentPetId = ctx.pets.at(currentPetIndex - 1)?.id
})

export const setNextPet = assign<SubscriptionFlowContext>((ctx) => {
  const currentPetIndex = ctx.pets.findIndex((pet) => pet.id === ctx.currentPetId)
  ctx.currentPetId = ctx.pets.at(currentPetIndex + 1)?.id
})

export const clearCurrentPet = assign<SubscriptionFlowContext>((ctx) => {
  ctx.currentPetId = undefined
})

export const addPromoCode = assign<SubscriptionFlowContext>((ctx) => {
  const promoCode = localStorage.getItem("promoCode")
  if (promoCode) {
    ctx.meta.promo_code = promoCode
    localStorage.removeItem("promoCode")
  }
})

export const setStep = assign<SubscriptionFlowContext, SetStepEvent>((ctx, { input }) => {
  ctx.step = input
})
